<template>
  <div
    class="videoList-box"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <!--操作栏-->
    <refreshbtn @refesh="refesh">
      <template slot="search-btns">
        <el-button type="success" size="small" v-has="'/api/customer/terminal/create'" @click="addFromMethod">添加</el-button>
      </template>
    </refreshbtn>
    <!--数据表格-->

    <el-table
      :data="tableData"
      style="width: 100%"
      border
      ref="multipleTable"
      v-loading="loading"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      height="580"
      :key="updataSta"
    >
      <el-table-column
        prop="terminal_id"
        label="终端id"
        align="center"
        width="80"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        prop="cat_id"
        label="密钥"
        align="center"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.secret_key || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="终端名称" align="center">
      </el-table-column>
      <el-table-column label="责任人" align="center" prop="cdo">
      </el-table-column>
      <el-table-column
        label="上传视频数"
        width="100"
        prop="video_count"
        align="center"
      >
      </el-table-column>
      <el-table-column label="是否启用" prop="enable" align="center" width="150">
        <template slot-scope="scope">
          <el-switch
            @change="updateData(scope.row)"
            v-model="scope.row.enable"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="update_time" align="center" width="250">
      </el-table-column>
      <el-table-column width="100" fixed="right" align="center" label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" v-has="'/api/customer/terminal/update'" @click="editForm(scope.row)">
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--封面图、视频弹窗组件-->
    <!-- 分页组件 -->
    <div class="bottom">
      <div class="page">
        <el-pagination
          :page-sizes="[10, 25, 50, 100]"
          :current-page="params.page"
          :page-size="params.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          v-if="total > 10"
          @size-change="changeSize"
          @current-change="changeCurrent"
        ></el-pagination>
      </div>
    </div>
    <addTerminal ref="addForm"></addTerminal>
  </div>
</template>

<script>
import addTerminal from "./components/addTerminal.vue";
import API from "@/api";
export default {
  data() {
    return {
      // 请求列表查询
      params: {
        page: 1,
        size: 10,
      },
      loading: false,
      // 列表数据
      tableData: [],
      cateData: [],
      total: "",
      dialogVisible: false,
      updataSta: false,
      isRefesh:true,
    };
  },
  components: {
    addTerminal,
  },
  created() {
    let listRule = '/api/customer/terminal/list'
    let obj = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('btnPower'))));
    if(obj[listRule]){
      this.isRefesh = true
      this.getList()
    }else{
      this.isRefesh = false 
    }
  },
  methods: {
    refesh(){
      this.isRefesh?this.getList():''
    },
    async updateData(row) {
      await API.updateTerminalList(row);
    },
    addFromMethod() {
      this.$refs.addForm.addFormData();
    },
    editForm(data) {
      this.$refs.addForm.editData(this._.cloneDeep(data));
    },
    getList() {
      this.loading = true
      API.getTerminalList(this.params)
        .then((res) => {
          if (res.code === 0 && res.data.data) {
            this.tableData = res.data.data;
            this.tableData.forEach((item) => {
              const timestamp = item.update_time * 1000;
              const date = new Date(timestamp);
              item.update_time = date.toLocaleString();
            });
            this.total = res.data.pagination.count;
            this.loading = false;
          } else if (res.code === 401) {
            this.$message.error("登陆信息过期,请重新登陆!");
            this.loading = false;
            return;
          } else {
            this.tableData = [];
            this.total = 0;
            this.loading = false;
          }
        })
        .catch(() => {
          this.tableData = [];
          this.loading = false;
        });
    },

    // 分页条数改变回调
    changeSize(size) {
      this.params.size = size;
      this.getList();
    },
    // 分页页码改变回调
    changeCurrent(number) {
      this.params.page = number;
      this.getList();
    },
  },
};
</script>
<style scoped lang="scss">
.picturePreview {
  max-width: 300px;
  display: flex;
}

.videoList-box {
  min-height: 100vh;
  overflow-y: auto;
}

.bottom {
  margin-top: 20px;

  .btn {
    display: inline-block;
    margin-left: 20px;
  }

  .page {
    display: inline-block;
    float: right;
  }
}

.el-pagination {
  margin-top: 0 !important;
}

.dialog-footer {
  left: 0;
}

.tagBox {
  display: inline-block;
  margin-right: 5px;
}
</style>
